<template>
  <v-container fluid>
    <v-row class="mt-2 px-12" v-if="dataLoading">
      <v-col cols="12">
        <v-skeleton-loader type="text" max-width="200"></v-skeleton-loader>
      </v-col>

      <v-col cols="12" md="6" v-for="i in 12" :key="i + 12">
        <v-skeleton-loader type="text"></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-card flat outlined class="pa-sm-4 wraperx" v-else>
      <v-form @submit.prevent ref="form">
        <v-card-title class="primary--text">
          New Lieu Application
          <v-spacer></v-spacer>
        </v-card-title>

        <v-card-text>
          <v-row class="mt-2">
            <v-col cols="12" md="6" class="py-0">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                v-model="menu1"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    flat
                    outlined
                    label="From Date *"
                    v-model="application.from_date"
                    :rules="[rules.required]"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="application.from_date"
                  color="primary"
                  no-title
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                min-width="auto"
                v-model="menu2"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    flat
                    outlined
                    label="To Date *"
                    v-model="application.to_date"
                    :rules="[rules.required]"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="application.to_date"
                  color="primary"
                  no-title
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                flat
                outlined
                label="No of days *"
                type="number"
                v-model="application.no_of_days"
                :rules="[rules.required, rules.moreThanZero]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-text-field
                flat
                outlined
                label="Department/Location *"
                v-model="application.department"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <v-textarea
                flat
                outlined
                label="Reason"
                v-model="application.reason"
                :rules="[rules.required]"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn color="secondary" depressed @click="goBack" text class="mx-3">
            cancel
          </v-btn>
          <v-btn
            color="primary"
            depressed
            @click="createLieuApplication"
            :loading="loading"
          >
            Save
          </v-btn>
        </v-card-title>
      </v-form>
    </v-card>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { v4 as uuidv4 } from "uuid";

import { addLieuApplication } from "@/services/lieuApplicationsService";
import { fetchAndStoreStaffData } from "@/services/preloadDataService";

export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    menu1: false,
    menu2: false,
    loading: false,
    dataLoading: false,
    rules: {},
    application: {
      uuid: "",
      staff_uuid: "",
      from_date: "",
      to_date: "",
      no_of_days: null,
      department: "",
      reason: "",
      review_status: "PENDING",
    },
  }),
  async created() {
    this.rules = {
      required: (value) => !!value || "Required.",
      moreThanZero: (value) => value > 0 || "Days must be more tan 0.",
      futureDate: (value) => {
        if (!value) return true;
        const inputDate = new Date(value);
        const today = new Date();
        inputDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
        return inputDate > today || "The date must be in the future.";
      },
    };
    if (
      this.$_checkPermission(
        this.auth.permissions,
        "Manage Lieu Applications",
        "Create"
      ) && this.auth.type == 'Regular Contract Staff'
    ) {
      this.dataLoading = true;
      this.staff = await fetchAndStoreStaffData();
      this.staff.forEach((driver) => {
        driver["name"] = driver.first_name + " " + driver.last_name;
      });
      this.dataLoading = false;
    } else {
      this.$router.push({
        name: "PageDashboard",
      });
    }
  },
  mounted() {
    //
  },
  methods: {
    goBack() {
      this.$router.push({ name: "PageMyLieuApplicationList" });
    },
    viewItem(item) {
      this.$router.push({
        name: "PageViewLieuApplication",
        params: { uuid: item.uuid },
        query: { bc: item.name, mylog: "true" },
      });
    },
    async createLieuApplication() {
      if (this.$refs.form.validate()) {
        this.application.uuid = uuidv4();
        this.application.staff_uuid = this.auth.uuid;
        this.application.staff = this.staff.find(
          (staff) => staff.uuid == this.application.staff_uuid
        );
        this.loading = true;
        let addLieuApplicationReturn = await addLieuApplication(
          this.application
        );
        this.loading = false;
        if (addLieuApplicationReturn == "success") {
          this.viewItem(this.application);
        }
      } else {
        this.$store.commit("setError", {
          isError: true,
          error:
            "Some fields are missing or incorrect. Please check your input.",
        });
      }
    },
  },
  updated() {
    // this.application.no_of_days = this.$_calculateDays(
    //   this.application.from_date,
    //   this.application.to_date
    // );
  },
};
</script>